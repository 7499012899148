import React, { useCallback, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

import GameSummary from '../components/GamesPicker/Game/GameSummary';

import { ConfigContext } from '../../shared/context';

import PostBetResponseInterface from '../../shared/interfaces/GamesPicker/PostBetResponseInterface';

import { constans } from '../../shared/utils';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';
import { DrawsStore } from '../../lobby-engine/stores';
import TicketStore from '../../lobby-engine/stores/TicketStore';
import LinesStore from '../../lobby-engine/stores/LinesStore';
import StakeStore from '../../lobby-engine/stores/StakeStore';

import StakeEntity from '../../lobby-engine/entities/StakeEntity';

interface SummaryContainerProps {
  onClose(): void;
  draws: DrawsStore;
  ticket: TicketStore;
  lines: LinesStore;
  stakes: StakeStore;
}

const SummaryContainer: React.FC<SummaryContainerProps> = observer(
  ({ onClose, draws, ticket, lines, stakes }: SummaryContainerProps) => {
    const { game, modal, playerBalance } = useContext(RootStoreContext);
    const config = useContext(ConfigContext);
    const showModal = modal.showModal;
    const nearestDraw = draws.nearestDraw;
    const filteredDraws = draws.filteredDraws || [];
    const areNumbersPicked = ticket.isValid;
    const drawsCount = draws.selectedNumberDraws;
    const fastPicker = constans.lotteryNamesPickX.indexOf(game.gameStructure.data.lottery.structure.name) != -1;
    const isBetPermutated = lines.isBetPermutated();
    const isInstant = game.isInstant;
    const betLogicPending = isInstant ? game.instantGame.stage.isLogicPending : false;
    const playerWallet = playerBalance.wallet ? playerBalance.wallet : null;
    //const playerBalence = playerBalance.data ? playerBalance.data.toString() : null;

    useEffect(() => {
      ticket.setDesiredCurrency(config.currency);
      if (game.lotteryName.toLowerCase().includes('imprexis')) {
        playerBalance.getPlayerWallet();
      }
    }, [game, config.currency, ticket, playerBalance]);

    useEffect(() => {
      if (isBetPermutated) {
        draws.changeDrawsNumber(constans.defaultDrawsNumberIndex);
      }
    }, [isBetPermutated, draws]);

    const getSummaryInfoTxt = useCallback((): JSX.Element => {
      const linesCount = lines.realValidLinesCount;

      if (isInstant) {
        return <FormattedMessage id="play.summary.p2" values={{ linesNumber: linesCount }} />;
      }

      if (areNumbersPicked) {
        const properNearestDraw = nearestDraw ? nearestDraw.drawDatetime : null;
        const startingDrawTime = filteredDraws ? filteredDraws[0].drawDatetime : properNearestDraw;

        return (
          <FormattedMessage
            id="play.summary"
            values={{
              linesNumber: linesCount,
              drawsNumber: drawsCount,
              day: dayjs(startingDrawTime).format('dddd'),
              date: dayjs(startingDrawTime).format('HH:mm'),
            }}
          />
        );
      }

      return <></>;
    }, [areNumbersPicked, filteredDraws, drawsCount, isInstant, nearestDraw, lines.realValidLinesCount]);

    const onDrawClick = useCallback(
      (draw: number): void => {
        draws.changeDrawsNumber(draw);
      },
      [draws]
    );

    const onProceedBtnClick = useCallback((): Promise<PostBetResponseInterface> => {
      return game.postBet(config.userToken);
    }, [config.userToken, game]);

    const onConfirmClick = useCallback((): void => {
      return game.saveLottery();
    }, [game]);

    const onStakeClick = useCallback(
      (selectedStake: StakeEntity) => {
        stakes.changeSelectedStake(selectedStake);
      },
      [stakes]
    );

    return (
      <GameSummary
        {...{
          onClose,
          onStakeClick,
          betLogicPending,
          isInstant,
          onProceedBtnClick,
          onConfirmClick,
          onDrawClick,
          fastPicker,
          showModal,
          isBetPermutated,
        }}
        numberDraws={draws.numberOfDraws}
        stakes={stakes.stakesList}
        activeStake={stakes.selectedStake}
        betCost={ticket.betCost.getHumanAmount()}
        currency={ticket.betCost.currency}
        areNumbersPicked={areNumbersPicked}
        summaryInfoTxt={getSummaryInfoTxt()}
        drawsCount={filteredDraws.length}
        sendingBet={game.betPost.isLoading}
        playerWallet={playerWallet}
        lotteryName={game.lotteryName}
        playerBalance={playerBalance.data ? playerBalance.data.toString() : null}
      />
    );
  }
);

export default SummaryContainer;
