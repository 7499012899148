import AppConfigStore from './AppConfigStore';

interface BrandConfig {
  /* Human Redable Name of Brand */
  name: string;
  /* Retail related setting */
  retail: {
    /** URL to image used in retail printouts */
    logoUrl?: string;
    /**  */
    claimLine1?: string;
    claimLine2?: string;
  };
  customMessages?: {
    afterBetPurchase?: string;
  };
}

//@TODO: Replace CDN url from one fetched from config
const BrandsPerLangData: Record<string, Record<string, BrandConfig>> = {
  en: {
    premierbet: {
      name: 'Premierbet',
      retail: {},
      customMessages: {
        afterBetPurchase: 'Lotto draw results may take up to 40 minutes to be confirmed',
      },
    },
    paridirect: {
      name: 'Paridirect',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/paridirect.svg',
        claimLine1:
          'Thank you for playing with us. Only those who take a chance can win. </br>The ticket is valid for 7 days.',
        claimLine2: 'Paridirect, Victoire Inévitable!',
      },
    },
    ils: {
      name: 'ILS',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/ils.png',
        claimLine1: 'Thank you for playing with us. Only those who take a chance can win.',
        claimLine2: 'iLottoSolutions demo ticket',
      },
    },
    betese: {
      name: 'Betese',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/betese.jpeg',
        claimLine1: 'Thank you for playing ,more wins, more money! </br>The ticket is valid for 7 days.',
        claimLine2: null,
      },
    },
  },
  es: {
    premierbet: {
      name: 'Premierbet',
      retail: {},
      customMessages: {
        afterBetPurchase: 'Los resultados del sorteo de la lotería pueden tardar hasta 40 minutos en ser confirmados',
      },
    },
    paridirect: {
      name: 'Pari Direct',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/paridirect.svg',
        claimLine1: null,
        claimLine2: null,
      },
    },
    ils: {
      name: 'ILS',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/ils.png',
        claimLine1: 'Gracias por jugar con nosotros. Sólo aquellos que toman un riesgo pueden ganar.',
        claimLine2: 'Billete de demostración iLottoSolutions',
      },
    },
    betese: {
      name: 'Betese',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/betese.jpeg',
        claimLine1: 'Gracias por jugar, más victorias, más dinero! </br>El boleto es válido por 7 días.',
        claimLine2: null,
      },
    },
  },
  fr: {
    premierbet: {
      name: 'Premierbet',
      retail: {},
      customMessages: {
        afterBetPurchase: `Les résultats du tirage de la loterie
        peuvent prendre jusqu'à 40 minutes pour être confirmés`,
      },
    },
    paridirect: {
      name: 'Paridirect',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/paridirect.svg',
        claimLine1: `Merci d'avoir joué avec nous.<br/> Seuls ceux qui tentent leur chance peuvent gagner.
          <br/>La validité du ticket est de 7 jours`,
        claimLine2: 'Paridirect, Victoire Inévitable!',
      },
    },
    ils: {
      name: 'ILS',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/ils.png',
        claimLine1: 'Merci de jouer avec nous. Seuls ceux qui tentent leur chance peuvent gagner.',
        claimLine2: 'Ticket de démonstration iLottoSolutions',
      },
    },
    betese: {
      name: 'Betese',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/betese.jpeg',
        claimLine1: `Merci de jouer, plus de victoires, plus d'argent! </br>Le billet est valable pendant 7 jours.`,
        claimLine2: null,
      },
    },
  },
  pt: {
    premierbet: {
      name: 'Premierbet',
      retail: {},
      customMessages: {
        afterBetPurchase: 'Os resultados do sorteio da loto podem demorar até 40 minutos para serem confirmados',
      },
    },
    paridirect: {
      name: 'Paridirect',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/paridirect.svg',
      },
    },
    ils: {
      name: 'ILS',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/ils.png',
        claimLine1: 'Obrigado por jogar conosco. Só aqueles que arriscam podem ganhar.',
        claimLine2: 'Bilhete de demostração iLottoSolutions',
      },
    },
    betese: {
      name: 'Betese',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/betese.jpeg',
        claimLine1: 'Obrigado por jogar, mais vitórias, mais dinheiro! </br>O bilhete é válido por 7 dias.',
        claimLine2: null,
      },
    },
  },
  sw: {
    premierbet: {
      name: 'Premierbet',
      retail: {},
      customMessages: {
        afterBetPurchase: 'Matokeo ya droo ya Lotto yanaweza kuchukua hadi dakika 40 kuthibitishwa',
      },
    },
    pariddirect: {
      name: 'Paridirect',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/paridirect_playongo.svg',
        claimLine1: null,
        claimLine2: null,
      },
    },
    ils: {
      name: 'ILS',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/ils.png',
        claimLine1: 'Asante kwa kucheza nasi. Ni wale tu wanaochukua hatari wanaweza kushinda.',
        claimLine2: 'Tiketi ya onyesho la iLottoSolutions',
      },
    },
    betese: {
      name: 'Betese',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/betese.jpeg',
        claimLine1: 'Asante kwa kucheza, ushindi zaidi, pesa zaidi! </br>Tiketi ni halali kwa siku 7.',
        claimLine2: null,
      },
    },
  },
  de: {
    premierbet: {
      name: 'Premierbet',
      retail: {},
      customMessages: {
        afterBetPurchase: 'Die Lotto-Ziehungsergebnisse können bis zu 40 Minuten zur Bestätigung benötigen.',
      },
    },
    paridirect: {
      name: 'Paridirect',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/paridirect.svg',
        claimLine1: 'Danke, dass Sie bei uns spielen. Nur wer wagt, kann gewinnen. </br>Das Ticket ist 7 Tage gültig.',
        claimLine2: 'Paridirect, Unvermeidlicher Sieg!',
      },
    },
    ils: {
      name: 'ILS',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/ils.png',
        claimLine1: 'Danke, dass Sie bei uns spielen. Nur wer wagt, kann gewinnen.',
        claimLine2: 'iLottoSolutions Demoticket',
      },
    },
    betese: {
      name: 'Betese',
      retail: {
        logoUrl: 'https://cdn.ilottosolutions.com/partners/betese.jpeg',
        claimLine1: 'Danke, dass Sie spielen, mehr gewinnen, mehr Geld! </br>Das Ticket ist 7 Tage gültig.',
        claimLine2: null,
      },
    },
  },
};

class BrandStore {
  appConfig: AppConfigStore;
  currentBrandId: string | undefined = undefined;
  currentBrandConfig: BrandConfig;
  defaultBrandConfig: BrandConfig = {
    name: 'Brand name',
    retail: {
      logoUrl: null,
      claimLine1: null,
      claimLine2: null,
    },
  };

  constructor(appConfig: AppConfigStore) {
    this.appConfig = appConfig;
  }

  setBrandId = (brandId: string): void => {
    this.currentBrandId = brandId;
    this.fetchBrandConfig();
  };

  fetchBrandConfig = () => {
    const lang = this.appConfig.language;
    const brandId = this.currentBrandId;
    const brandConfig = BrandsPerLangData[lang][brandId];
    this.currentBrandConfig = { ...this.getDefaultBrandConfig(), ...brandConfig };
  };

  data = () => {
    return this.currentBrandConfig;
  };

  getDefaultBrandConfig = (): BrandConfig => {
    const defaultBrandConfig: BrandConfig = {
      name: 'iLotto Solutions',
      retail: {
        logoUrl: null,
        claimLine1: null,
        claimLine2: null,
      },
    };
    return defaultBrandConfig;
  };
}

export default BrandStore;
