import { hot } from 'react-hot-loader/root';
import React from 'react';
import { IntlProvider } from 'react-intl';

import messagesEN from '../assets/i18n/en.json';
import messagesFR from '../assets/i18n/fr.json';
import messagesPT from '../assets/i18n/pt.json';
import messagesES from '../assets/i18n/es.json';
import messagesSW from '../assets/i18n/sw.json';
import messagesDE from '../assets/i18n/de.json';

import ErrorModal from './modals/ErrorModal';
import SuccessModal from './modals/SuccessModal';
import PermutationsModal from './modals/PermutationsModal';
import PickerModal from './modals/PickerModal';
import GamePickerModal from './modals/GamePickerModal';
import TicketSummaryModal from './modals/TicketSummaryModal';
import PendingModal from './modals/PendingModal';
import TicketModal from './modals/TicketModal';
import MultiTicketReceiptModal from './modals/MultiTicketReceiptModal';
import InstantTicketReceiptModal from './modals/InstantTicketReceiptModal';
import Error from '../pages/Error';
import Retail from './Retail/Retail';

import LobbyContainer from '../containers/LobbyContainer';

import { ConfigContext } from '../../shared/context';
import { IS_RETAIL } from '../../shared/utils';

import styles from '../assets/styles/index.scss';
import DepositModal from './modals/DepositModal';

type AppState = {
  error: Error | null;
};

class App extends React.Component<{}, AppState> {
  private language: string;

  constructor(props: {}) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error): void {
    this.setState({ error });
  }

  private setLanguage = (): { [key: string]: string } => {
    switch (this.language.toLowerCase()) {
      case 'en':
        return messagesEN;
      case 'fr':
        return messagesFR;
      case 'es':
        return messagesES;
      case 'pt':
        return messagesPT;
      case 'sw':
        return messagesSW;
      case 'de':
        return messagesDE;
      default:
        return messagesEN;
    }
  };

  private renderContent(): JSX.Element {
    if (IS_RETAIL) {
      return <Retail />;
    }

    return <LobbyContainer />;
  }

  public render(): React.ReactNode {
    this.language = this.context.lang.toUpperCase();

    if (this.state.error) {
      return Error(this.state.error);
    }

    return (
      <IntlProvider locale={this.language} messages={this.setLanguage()}>
        <div className={styles.lobbyMain}>
          {this.renderContent()}
          <ErrorModal />
          <PermutationsModal />
          <SuccessModal />
          <PickerModal />
          <GamePickerModal />
          <TicketSummaryModal />
          <TicketModal />
          <MultiTicketReceiptModal />
          <InstantTicketReceiptModal />
          <PendingModal />
          <DepositModal />
        </div>
      </IntlProvider>
    );
  }
}

App.contextType = ConfigContext;

export default hot(App);
