import dayjs from 'dayjs';
import { observable } from 'mobx';

import { DisplayTimeInterface } from '../../shared/interfaces/Entities/DrawTimeEntityInterface';
import AbstractTimer from './selections/AbstractTimer';

class DrawTimerEntity extends AbstractTimer {
  private countdownFrom: dayjs.Dayjs;
  @observable displayTime: DisplayTimeInterface = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  @observable onDeductedCallback: CallableFunction;

  setDrawTimer(): void {
    this.setTimer((): void => {
      const currentTime = dayjs();
      this.timeLeft = this.countdownFrom.diff(currentTime, 'millisecond');

      if (this.timeLeft <= 0) {
        if (typeof this.onDeductedCallback === 'function') {
          this.onDeductedCallback();
        }

        this.clearTimer();
        this.clearDisplayTime();

        return;
      }

      const days = Math.floor(this.countdownFrom.diff(currentTime, 'day'));
      const hours = Math.floor(this.countdownFrom.diff(currentTime, 'hour') % 24);
      const minutes = Math.floor(this.countdownFrom.diff(currentTime, 'minute') % 60);
      const seconds = Math.floor(this.countdownFrom.diff(currentTime, 'second') % 60);

      this.displayTime = {
        days,
        hours,
        minutes,
        seconds,
      };

      return;
    });
  }

  clearDrawTimer(): void {
    this.clearTimer();
    this.clearDisplayTime();
  }

  clearDisplayTime(): void {
    this.displayTime = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  setCountdownFrom(drawDate: string): void {
    this.countdownFrom = dayjs(drawDate, 'YYYY-MM-DD HH:mm:ss-ZZ');
  }
}

export default DrawTimerEntity;
